import React, {useState} from 'react';
import {
  Box,
  ColumnLayout,
  Popover,
  SpaceBetween
} from '@amzn/awsui-components-react';
import {EventsTableItem} from 'src/interfaces';
import {HiLink} from 'react-icons/hi';
import Button from "@amzn/awsui-components-react/polaris/button";
import {OverrideModal} from "src/components/SitePage/OverrideModal";
import {DeviceNotesModal} from "src/components/SitePage/DeviceNotesModal";

function DeviceCellInfo({item, sectionId}: {
  item: EventsTableItem,
  sectionId: string
}) {
  const [overrideModalVisible, setOverrideModalVisible] = useState(false);
  const [deviceNotesModalVisible, setDeviceNotesModalVisible] = useState(false);

  const showDeviceNotesModal = () => {
    setDeviceNotesModalVisible(true);
  }

  const showOverrideModal = () => {
    setOverrideModalVisible(true);
  }

  return (
    <div>
      <Popover
        fixedWidth
        size='large'
        header={item.device_name}
        content={
          <SpaceBetween direction='vertical' size='m'>
            <ColumnLayout columns={2} variant='text-grid'>
              <SpaceBetween size='m'>
                <div>
                  <Box variant='awsui-key-label'>
                    Parent Device
                  </Box>
                  <div>{item.parent_device_name || '-'}</div>
                </div>
                <div>
                  <Box variant='awsui-key-label'>
                    Child Device
                  </Box>
                  <div>{item.child_device_name || '-'}</div>
                </div>
                <div>
                  <Box variant='awsui-key-label'>
                    Subchild Device
                  </Box>
                  <div>{item.subchild_device_name || '-'}</div>
                </div>
              </SpaceBetween>
              <SpaceBetween size='m'>
                <div>
                  <Box variant='awsui-key-label'>
                    Parent Device ID
                  </Box>
                  <div>{item.parent_device_id || '-'}</div>
                </div>
                <div>
                  <Box variant='awsui-key-label'>
                    Child Device ID
                  </Box>
                  <div>{item.child_device_id || '-'}</div>
                </div>
                <div>
                  <Box variant='awsui-key-label'>
                    Subchild Device ID
                  </Box>
                  <div>{item.subchild_device_id || '-'}</div>
                </div>
                {item.linked_device_ids ? (
                  <div>
                    <Box variant='awsui-key-label'>
                      Linked Device IDs
                    </Box>
                    <div>{item.linked_device_ids.join('; ') || '-'}</div>
                  </div>
                ) : null}
              </SpaceBetween>
            </ColumnLayout>
            <SpaceBetween direction='vertical' size='xs'>
              <div>
                <Box variant='awsui-key-label'>
                  Device Notes
                </Box>
                <div>
                  {(item.device_notes && item.device_notes.length > 0) ? item.device_notes.join('; ') : '-'}
                </div>
              </div>
              <Button
                variant={'normal'}
                onClick={() => showDeviceNotesModal()}
              >
                Update Notes
              </Button>
            </SpaceBetween>
            <Button
              variant={'normal'}
              onClick={() => showOverrideModal()}
            >
              Override N/A
            </Button>
          </SpaceBetween>
        }
      >
        {item.device_name}
        {item.linked_device_ids ? (
          <HiLink className={'inline'}/>
        ) : null}
      </Popover>
      {deviceNotesModalVisible ? (
        <DeviceNotesModal
          item={item}
          sectionId={sectionId}
          isVisible={deviceNotesModalVisible}
          setVisible={setDeviceNotesModalVisible}
        />
      ) : null}
      {overrideModalVisible ? (
        <OverrideModal
          item={item}
          sectionId={sectionId}
          isVisible={overrideModalVisible}
          setVisible={setOverrideModalVisible}
        />
      ) : null}
    </div>
  )
}

export default DeviceCellInfo;